<template>
  <div>
    <vs-popup class="sm:popup-w-70" title="Tambah Group 1" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" label-placeholder="Kode *" v-model="data.kode"/>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" label-placeholder="Nama *" v-model="data.nama"/>
          </div>
          <div class="vx-col sm:w-1/3 w-full -mt-1">
            <label class="ml-1 text-xs opacity-75">Jenis *</label>
            <v-select :options="jenises" label="jenis" :reduce="item => item.jenis" :clearable="false" v-model="data.jenis"/>
          </div>
        </div>
        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>

    </vs-popup>
  </div>
</template>

<script>
import ItemGroup1Repository from '@/repositories/master/item-group1-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import vSelect from 'vue-select'

export default {
  name: 'ItemGroup1Add',
  components: { ValidationErrors, 'v-select': vSelect },
  props: ['isActive'],
  data () {
    return {
      isLoading: false,
      errors: null,
      data: {},
      jenises: [
        { jenis: 'Unit' },
        { jenis: 'Non Unit' }
      ]
    }
  },
  methods: {
    save () {
      this.errors = null
      this.isLoading = true

      ItemGroup1Repository.create(this.data)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      Object.assign(this.$data, this.$options.data.call(this))
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
